<template>
  <Title>{{ `一个橙子,软件开发,软件制作,软件运维,软件推荐|www.yigechengzi.com` }}</Title>
  <Meta name="keywords" content="前端,Vue,React,Angular,Nuxt,Howtools,Howuse,营销,SCADA">
  </Meta>
  <Meta name="description" content="一个橙子pro，一个资深的前端开发工程师，前端库howtools、howuse的作者">
  </Meta>

  <NuxtLayout>
    <Notification />
    <!-- 文章列表 -->
    <div v-for="(article, index) in records" class=" text-black dark:text-white py-2 hover:bg-[#FAFAFA]">
      <a :href="`/article_detail/${article.id}.html`">
        <h2 class=" text-xl font-semibold hover:underline hover:text-vip-2">{{ article.title }}</h2>
        <p class=" text-sm text-gray-500 my-2">{{ article.summary }}</p>
        <div class=" text-gray-400 flex gap-x-8 flex-wrap">
          <div class=" inline-flex gap-2 flex-wrap ">
            <span v-for="tag in tags(article.tag)" class="bg-[#f2f3f5] px-1 rounded flex items-center text-sm">{{
              tag
            }}</span>
            <span>更新时间：{{ article.updatedAt }}</span>
          </div>
        </div>
      </a>
    </div>
    <div class=" text-center py-2 text-xl hover:text-blue-500 hover:underline">
      <a href="/article/2.html">查看更多</a>
    </div>
  </NuxtLayout>
</template>

<script lang="ts" setup>
const tags = (tag: string) => tag ? tag.split(",") : []

const config = useRuntimeConfig().public

const { data, status } = await useAsyncData<{ data: any }>("blog-list", async () => {
  const result = await $fetch<{ data: any }>(`${config.apiBase}/blog/article/list`, {
    parseResponse: JSON.parse,
  })

  return result
})

const records = computed(() => {
  return unref(data)?.data?.records
})
 
</script>
<style scoped lang="less">
.user-info {
  cursor: pointer;
  margin-right: 16px;
}

@keyframes ArticleblowUp {
  0% {
    transform: scale(1) translateX(0);
  }

  100% {
    transform: scale(1.01) translateX(5px);
  }
}

.index-footer {
  text-align: center;
  background-color: #000;
  color: #FFF;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  a {
    text-decoration: none;

    &:link {
      text-decoration: none;
      color: #FFF;
    }

    &:visited {
      text-decoration: none;
      color: #FFF;
    }

    &:hover {
      text-decoration: none;
      color: #FFF;
    }

    &:active {
      text-decoration: none;
      color: #FFF;
    }
  }
}
</style>
